import './bootstrap';
import '../css/app.css';

import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import { Toaster } from 'react-hot-toast';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51IssiHJvc5NxDsL0D549eawGC7d7F1yXD4J6kIefnliJ72HgqLLprBwXGhwc5bGh1XC5V7GWx5O7pAEjsmP4AjNd00ZNXEk8KL');

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';


Sentry.init({
    dsn: "https://273a3038c30c77b8eae17e849777efcd@o4507893420720128.ingest.de.sentry.io/4507893510176848",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(<Sentry.ErrorBoundary><Elements stripe={stripePromise}><App {...props} /><Toaster /></Elements></Sentry.ErrorBoundary>);
    },
    progress: {
        color: '#4B5563',
    },
});
